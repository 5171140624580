<template>
  <div class="build-page">
    <div class="build-container w-1200 margin-auto">
      <!--estate-head start-->
      <div class="estate-head">
        <div class="head-bor flex">
          <div class="head-select w-105">
            <el-select @change="changeHousing" v-model="housingTypeIndex" placeholder="请选择">
              <el-option
                v-for="item in housingType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="head-line"></div>
          <div class="head-input w-670">
            <el-input v-model="from.name" placeholder="请输入内容"></el-input>
          </div>
        </div>
        <el-button class="estate-head-btn" @click="init">搜索</el-button>
      </div>
      <!--estate-head end-->
      <!--build-choice start-->
      <div class="build-choice w-1200 margin-auto">
        <!--choice-item start-->
        <div class="choice-item">
          <div class="label">区域</div>
          <div class="item-r">
            <el-cascader
              size="large"
              clearable
              :options="regionList"
              v-model="areaList"
              placeholder="请选择所在区域"
              @change="handleChangeCity"
            >
            </el-cascader>
          </div>
        </div>
        <!--choice-item end-->
        <!--choice-item start-->
        <div class="choice-item">
          <div class="label">商圈范围</div>
          <div class="item-r">
            <ul class="choice-ul">
              <li @click="changeType('','shangquan')" :class="{active:from.shangquan == ''}">不限</li>
              <li @click="changeType(item.id,'shangquan')" :class="{active:from.shangquan == item.id}" v-for="(item,index) in shangquanList" :key="index">{{ item.name }}</li>
            </ul>
          </div>
        </div>
        <!--choice-item end-->
        <!--choice-item start-->
        <div class="choice-item">
          <div class="label">小区类型</div>
          <div class="item-r">
            <ul class="choice-ul">
              <li @click="changeType('','fang_type')" :class="{active:from.fang_type == ''}" v-show="!isOfficeBroker">不限</li>
              <li @click="changeType(item.value,'fang_type')" :class="{active:from.fang_type == item.value}" v-for="(item,index) in getZuHousingTypeList().filter(option => option.value !== -1)" :key="index">{{ item.label }}</li>
            </ul>
          </div>
        </div>
        <!--choice-item end-->
        <!--choice-item start-->
        <div class="choice-item">
          <div class="label">价格区间</div>
          <div class="item-r">
            <ul class="choice-ul">
              <li @click="changeType('','junjia_price')" :class="{active:from.junjia_price == ''}" >不限</li>
              <li @click="changeType(item.value,'junjia_price')" :class="{active:from.junjia_price == item.value}" v-for="(item,index) in zuPriceList" :key="index">{{ item.label }}</li>
              <li>
                <div class="choice-form">
                  <div class="choice-input">
                    <el-input v-model="from.min_price" placeholder="请输入"></el-input>
                  </div>
                  <div class="choice-line"></div>
                  <div class="choice-input">
                    <el-input v-model="from.max_price" placeholder="请输入"></el-input>
                  </div>
                  <el-button @click="submitPrice">提交</el-button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!--choice-item end-->
        <!--choice-item start-->
        <div class="choice-item">
          <div class="label">排序</div>
          <div class="item-r">
            <ul class="choice-ul">
              <li @click="changeType(item.value,'paixu')" :class="{active:item.value == from.paixu}" v-for="(item,index) in plotSort" :key="index">{{ item.label }}</li>
            </ul>
          </div>
        </div>
        <!--choice-item end-->
      </div>
      <!--build-choice end-->
      <!--estate-container start-->
      <div class="estate-container p-t-20 p-b-20 m-t-40">
        <!--estate-list start-->
        <div class="estate-list">
          <ul class="estate-list-ul">
            <li @click="hrefDetail(item)" v-for="(item,index) in list" :key="index">
              <div class="li-img">
                <img :src="item.fengmian_image" />
              </div>
              <div class="li-txt">
                <div class="li-t flex">
                  <div class="t">{{ item.name }}</div>
                  <!-- <div class="t-icon">
                    <img src="../../assets/image/houseing/house20.png" />
                  </div> -->
                </div>
                <div class="li-a">{{ item.city }} {{ item.address }} {{ item.jian_time ? item.jian_time : '' }}{{ item.jian_time ? '建' : '' }}</div>
                <div class="li-tags">
                  <span class="tag" v-for="(item,index) in item.spans" :key="index">{{ item }}</span>
                </div>
                <!-- <div class="li-p">
                  <div class="p">出售均价{{ item.junjia_price }}元/m²</div>
                  <div class="g">{{ item.zaishou_number }}套在售</div>
                </div> -->
                <div class="li-p">
                  <div class="p">出租均价{{ item.zu_price }}元/月</div>
                  <div class="g">{{ item.zu_number }}套在租</div>
                </div>
                <div class="li-p">
                  <div class="p">面积区间{{ item.mianji.join('-') }}㎡</div>
                </div>
              </div>
            </li>
          </ul>
          <div class="paging" v-if="list.length">
            <el-pagination
              background
              @current-change="changePage"
              layout="prev, pager, next, jumper, ->, total"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <!--estate-list end-->
        <!--estate-right start-->
        <div class="estate-right">
          <div class="estate-hd">
            <!-- <div @click="changeHotType(index)" class="li" :class="{active:from.type == index + 1}" v-for="(item,index) in 2" :key="index">热{{ index == 0 ? '卖' : '租' }}房源</div> -->
            <div class="li active">热租房源</div>
          </div>
          <ul class="estate-vertical">
            <li v-for="(item,index) in hotList" :key="index">
              <div class="li-img">
                <img :src="item.fang_fengmian_image" />
              </div>
              <div class="li-pos">{{ item.fang_level }}</div>
              <div class="li-txt">
                <div class="li-t">{{ item.house_name }}</div>
                <div class="li-c m-t-15 m-b-15">
                  {{ item.fang_xing }} | {{ item.fang_mianji }}㎡ | {{ item.dian_number }}次
                </div>
                <div class="li-p">
                  <div class="p m-r-20" v-if="item.type == 1">
                    <span class="red">{{ item.zong_price }}万</span>
                    <img
                      class="p-icon"
                      src="../../assets/image/houseing/up2.png"
                    />
                  </div>
                  <div class="p">
                    <span class="gray">{{ item.can_price }}元/月</span>
                    <!-- <img
                      class="p-icon"
                      src="../../assets/image/houseing/up1.png"
                    /> -->
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!--estate-right end-->
      </div>
      <!--estate-container end-->
      <!---右侧悬浮 start-->
      <div class="build-fix">
        <div class="fix-item li2">
          <div class="t" @click="goUrl(2)">钥匙管理</div>
        </div>
        <div class="fix-item li3">
          <div class="t" @click="goUrl(3)">房源录入</div>
        </div>
      </div>
      <!---右侧悬浮 end-->
    </div>
     <!-- 选择房源 S -->
     <select-housing @select="selectHousing" ref="selectHousing"></select-housing>
    <!-- 选择房源 E -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { regionData, CodeToText } from "element-china-area-data";
import SelectHousing from "@/components/SelectHousing.vue";
import {getUserAgent} from "ali-oss/lib/common/utils/getUserAgent";
export default {
  components: { SelectHousing },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo","city","region"]),
  },
  data() {
    return {
      total: 0, //条数
      list: [], //列表
      regionList: regionData,
      CodeToText: CodeToText,
      areaList: [], //区域列表
      housingTypeIndex:'/estate?type=1',//
      isOfficeBroker: false,
      from:{
        city:'',
        name:'',
        shangquan:'',//商圈
        junjia_price:'',//
        zu_price:'',//
        paixu:'1',//排序1=均价从低到高2=均价从高到低3=热门低到高4=热门高到低
        page:1,//
        min_price:'',
        max_price:'',
        type:2,//1=出售2=出租
        fang_type:''
      },
      shangquanList:[],//商圈列表
      hotList:[],//热卖热阻房源
      options: [
        {
          value: 2,
          label: "住宅"
        },
        {
          value: 3,
          label: "公寓"
        },
        {
          value: 4,
          label: "写字楼"
        },
        {
          value: 5,
          label: "商铺"
        },
        {
          value: 6,
          label: "别墅"
        },
        {
          value: 7,
          label: "安置小区"
        }
      ],
    };
  },
  methods: {
     //选择房源
     selectHousing (index) {
      if (index == 0) {
        this.$router.push({ path: "/addHousing", query: { type: 1 } });
      } else if (index == 1) {
        this.$router.push({ path: "/addRentHousing", query: { type: 2 } });
      } else if (index == 2) {
        console.log("000");

      } else if (index == 4) {
        this.$refs.applyPlot.open();
      } else if (index == 5) {
        this.$router.push({ path: '/estate?type=1' })
      }
    },
    goUrl (index) {
      if (index == 1) {
        this.$router.push({ path: "/housing?type=2" });
      } else if (index == 2) {
        this.$router.push({ path: "/personage/keyRate" });
      } else if (index == 3) {
        this.$refs.selectHousing.dialogVisible = true;
        // this.$router.push({ path: "/addHousing?type=1" });
      }
    },
    changePage (e) {
      this.from.page = e
      this.getPlotList()
    },
    //提交金额
    submitPrice () {
      if (this.from.min_price && this.from.max_price || this.from.min_price == 0 && this.from.max_price != 0) {
        this.from.junjia_price = this.getPrice()
        this.init()
      }
    },
    getPrice () {
      if (Number(this.from.min_price) < Number(this.from.max_price)) {
        return this.from.min_price + ',' + this.from.max_price
      } else {
        let min_price = this.from.min_price
        let max_price = this.from.max_price
        this.from.max_price = min_price
        this.from.min_price = max_price
        return this.from.min_price + ',' + this.from.max_price
      }
    },
    //切换类型
    changeHousing(e) {
      if (e === this.$route.fullPath) return
      this.$router.push({path:e})
    },
    //筛选
    changeType (value,type) {
      this.from[type] = value
      this.init()
    },
    //切换热卖房源
    changeHotType (index) {
      this.from.type = index + 1
      this.getHotPlotList()
    },
    //跳转页面
    hrefDetail (item) {
      this.$router.push({ path: "/estateDetail" ,query:{id:item.id,type:this.$route.query.type}});
    },
    init () {
      this.from.page = 1
      this.list = []
      this.getPlotList()
    },
    //获取小区列表
    getPlotList () {
      this.$axios.xiaoQuList(this.from).then(res=>{
        this.list = res.data.data
        this.total = res.data.total
      })
    },
    //获取商圈
    getshangQuan () {
       this.$axios.shangQuan({
        city:this.from.city || this.region
      }).then(res=>{
        this.shangquanList = res.data
      })
    },
    //选择区域
    handleChangeCity () {
      let data = []
      for (let i = 0; i < this.areaList.length; i++) {
        data.push(this.CodeToText[this.areaList[i]]);
      }
      this.from.city = data.join('/')
      this.init()
      this.getshangQuan()
    },
    getHotPlotList () {
      this.$axios.hotHouse({type:this.isOfficeBroker ? 4: this.from.type,pageNum:5}).then(res=>{
        this.hotList = res.data.data
      })
    },
    initializeComponent() {
      this.isOfficeBroker = this.userInfo.fang_type_privilege === 1;
      this.from.fang_type = this.isOfficeBroker ? 4 : '';
      this.getPlotList();
      this.getshangQuan();
      this.getHotPlotList();
    },
  },
  created () {
    if (!this.userInfo || !Object.keys(this.userInfo).length) {
      // 如果userInfo为空或未定义，获取用户信息后再执行后续操作
      this.getUserInfo().then(() => {
        this.initializeComponent();
      });

    } else {
      // 如果userInfo已经存在，直接初始化组件
      this.initializeComponent();
    }
  }
};
</script>

<style lang="less" scoped>
.f-weight {
  font-weight: 600;
}
.m-l-10 {
  margin-left: 10px;
}
.m-l-5 {
  margin-left: 5px;
}
.build-page {
  background: #fff;
}
.build-container {
  padding: 35px 0px;
}
.estate-head {
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  .head-bor {
    border: 2px solid #ebebeb;
    width: 780px;
    border-right: none;
  }
  .w-105 {
    width: 105px;
  }
  /deep/ .el-input__inner {
    border: none;
    height: 56px;
    line-height: 56px;
    font-size: 18px;
  }
  .head-line {
    width: 1px;
    height: 36px;
    background: #ebebeb;
  }
  .w-670 {
    width: 670px;
  }
  .estate-head-btn {
    width: 120px;
    height: 60px;
    border-radius: 0;
    color: #fff;
    background: #3273f6;
    font-size: 18px;
    border: 2px solid #3273f6;
  }
}
.build-choice {
  .choice-item {
    display: flex;
    margin: 20px 0px;
  }
  .label {
    width: 100px;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    min-width: 100px;
    max-width: 100%;
  }
  .item-r {
    display: flex;
    flex-wrap: wrap;
  }
  .choice-ul {
    display: flex;
    flex-wrap: wrap;
  }
  .choice-ul li {
    margin-right: 40px;
    font-size: 16px;
    line-height: 32px;
    display: flex;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .choice-ul li span {
    line-height: 32px;
  }
  .choice-ul li.active {
    color: #3273f6;
  }
  .choice-form {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #666;
    /deep/ .el-button {
      height: 24px;
      line-height: 24px;
      margin-left: 20px;
      padding: 0;
      width: 50px;
    }
  }
  .choice-input {
    width: 80px;
  }
  .choice-icon img {
    width: 12px;
    height: 8px;
  }
  .input-tip {
    font-size: 16px;
    color: #666;
  }
  /deep/ .choice-input .el-input__inner {
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    border-radius: 0px;
  }
  .choice-line {
    width: 20px;
    height: 1px;
    background: #ccc;
    margin: 0px 10px;
  }
  /deep/ .el-checkbox__label {
    font-size: 16px;
  }
}

.estate-container {
  border-top: 1px solid #ebebeb;
  display: flex;
  justify-content: space-between;
  .estate-list {
    width: 880px;
    .estate-list-ul li {
      display: flex;
      padding: 25px 0px;
      border-bottom: 1px solid #ebebeb;
    }
    .li-img img {
      width: 220px;
      height: 165px;
      border-radius: 8px;
      display: block;
    }
    .li-img {
      min-width: 220px;
      max-width: 220px;
      margin-right: 18px;
    }
    .li-txt {
      width: calc(100% - 238px);
    }
    .t {
      font-size: 18px;
      font-weight: 600;
      width: calc(100% - 30px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .t-icon {
      width: 20px;
      height: 23px;
      min-width: 20px;
      max-width: 20px;
    }
    .li-a {
      font-size: 16px;
      color: #666;
      line-height: 1.5;
      margin: 15px 0px;
    }
    .li-tags {
      display: flex;
      margin: 15px -5px;
    }
    .li-tags .tag {
      margin: 5px;
      background: #f5f5f5;
      border-radius: 3px;
      padding: 0 10px;
      height: 28px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #999;
    }
    .li-p {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0px;
      font-size: 18px;
      align-items: center;
    }
    .li-p .p {
      font-size: 22px;
      color: #ff2121;
      font-weight: 600;
    }
    .li-p .g {
      font-size: 18px;
      color: #999;
    }
  }
  .estate-right {
    width: 280px;
    .estate-hd {
      display: flex;
      padding: 25px 0;
      border-bottom: 1px solid #ebebeb;
      justify-content: space-around;
      .li {
        font-size: 18px;
        font-weight: 600;
        position: relative;
        cursor: pointer;
      }
      .active {
        color: #3273f6;
      }
      .active:after {
        width: 24px;
        height: 2px;
        position: absolute;
        bottom: -15px;
        left: 50%;
        margin-left: -12px;
        display: block;
        content: "";
        background: #3273f6;
      }
    }
    .estate-vertical li {
      margin: 20px 0px;
      border: 1px solid #ebebeb;
      position: relative;
      .li-img img {
        display: block;
        width: 100%;
        height: 210px;
      }
      .li-pos {
        border-radius: 0px 0px 8px 0px;
        width: 56px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.3);
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        position: absolute;
        left: 0;
        top: 0;
      }
      .li-txt {
        padding: 15px 10px;
        .li-t {
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .li-c {
          font-size: 14px;
          color: #999;
        }
        .li-p {
          display: flex;
        }
        .red {
          font-size: 22px;
          color: #ff2121;
          font-weight: 600;
        }
        .gray {
          font-size: 18px;
          color: #999;
        }
        .p {
          display: flex;
        }
        .p-icon {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.build-fix {
  position: fixed;
  right: 0px;
  z-index: 99999;
  top: 25%;
  text-align: center;
  .fix-item {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border: 1px solid #ebebeb;
    background: #fff;
    cursor: pointer;
  }
  .fix-item.li1 {
    background: #fff url("../../assets/image/houseing/house1.png") no-repeat
      center 20px;
  }
  .fix-item.li2 {
    border-bottom: none;
    background: #fff url("../../assets/image/houseing/house2.png") no-repeat
      center 20px;
  }
  .fix-item.li3 {
    background: #fff url("../../assets/image/houseing/house3.png") no-repeat
      center 20px;
  }
  .fix-item .t {
    padding-top: 40px;
  }
  .fix-item.active .t {
    color: #fff;
  }
  .fix-item.li1.active {
    background: #3273f6 url("../../assets/image/houseing/house4.png") no-repeat
      center 20px;
  }
  .fix-item.li2.active {
    background: #3273f6 url("../../assets/image/houseing/house5.png") no-repeat
      center 20px;
  }
  .fix-item.li3.active {
    background: #3273f6 url("../../assets/image/houseing/house6.png") no-repeat
      center 20px;
  }
}
</style>
