<template>
  <div class="housing">
    <el-dialog
      title="请选择房源类型"
      :visible.sync="dialogVisible"
      width="860px"
      center
      custom-class="housing_mian"
      :show-close="false"
      :close-on-click-modal="false"
    >
    <i class="el-icon-circle-close" @click="handleClose"></i>
      <ul class="housing_list flex">
        <li v-for="(item,index) in housingList" :key="index" @click="selectHousing(item.index)">
          <img :src="item.image" alt="">
          <span>{{ item.name }}</span>
          <input type="file" v-if="item.index == 2 || item.index == 3" @input="uploadFile($event,index)">
        </li>
      </ul>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data () {
    return {
      dialogVisible:false,//是否展示
      housingList:[
        // {
        //   name:'出售',
        //   image:require('../assets/image/home/sell.png')
        // },
        {
          name:'出租',
          image:require('../assets/image/home/rent.png'),
          index:1
        },
        // {
        //   name:'出售导入',
        //   image:require('../assets/image/home/batch.png')
        // },
        {
          name:'出租导入',
          image:require('../assets/image/home/batch.png'),
          index:3
        },
        {
          name:'申请小区',
          image:require('../assets/image/home/apply.png'),
          index:4
        },
        {
          name:'完善小区',
          image:require('../assets/image/home/perfect.png'),
          index:5
        }
      ]
    }
  },
  methods:{
    //上传文件
    uploadFile (e,type) {
      this.$axios.fangImport({file:e.target.files[0],type:type === 2 ? 1 : (this.userInfo.fang_type_privilege === 1 ? 4 : 2)}).then(res=>{
        console.log(res.data);
      })
    },
    //选择那个
    selectHousing (index) {
      this.handleClose()
      this.$emit('select',index)
    },
    //关闭弹窗
    handleClose () {
      this.dialogVisible = false
    }
  }
};
</script>

<style lang="less" scoped>
.housing_mian {
  .el-icon-circle-close {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
  }
  .housing_list {
    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      // margin-right: 70px;
      position: relative;
      &:last-child {
        margin-right: 0;
      }
      input {
        width: 100%;
        height: 100%;
        display: block;
        opacity: 0;
        cursor: pointer;
        position: absolute;
      }
      img {
        width: 86px;
        height: 86px;
        border-radius: 50%;
      }
      span {
        color: #333333;
        font-size: 24px;
        margin-top: 20px;
      }
    }
  }
}
</style>
